export const loginForm = [
    { id: 1, code: 'username', name: 'Username', placeholder: 'Username', required: true, value: '', type: 'text', maxLength: 36 },
    { id: 1, code: 'password', name: 'Password', placeholder: 'Password', required: true, value: '', type: 'password', maxLength: 36 }
];

export const customerForm = [
    { id: 1, code: 'name', name: 'Name', required: true, value: '', type: 'text', maxLength: 56 },
    { id: 2, code: 'address', name: 'Address', required: false, value: '', type: 'text', maxLength: 256 },
    { id: 3, code: 'phone', name: 'Phone Number', required: true, value: '', type: 'number', maxLength: 10 },
    { id: 4, code: 'email', name: 'Email', required: false, value: '', type: 'email', maxLength: 56 },
    { id: 5, code: 'regno', name: 'Rego Number', required: true, value: '', type: 'text', maxLength: 56 },
    { id: 6, code: 'car_type', name: 'Car Type', required: true, value: '', type: 'list', maxLength: 100 },
    { id: 7, code: 'abn_number', name: 'ABN Number', required: false, value: '', type: 'text', maxLength: 11 }

];

export const searchCustomerForm = [
    { id: 1, code: 'search', name: 'Phone Number/ Rego Number', required: false, value: '', type: 'text', maxLength: 56 }
];

export const carTypesForm = [
    { id: 1, code: 'name', name: 'Name', required: true, value: '', type: 'text' },
    { id: 2, code: 'status', name: 'Active', value: true, type: 'checkbox' }
];

export const serviceForm = [
    { id: 1, code: 'name', name: 'Service Name', required: true, value: '', type: 'text' },
    { id: 2, code: 'price', name: 'Price', required: true, value: '', type: 'text' },
    { id: 3, code: 'status', name: 'Active', value: true, type: 'checkbox' }
];

export const planForm = [
    { id: 1, code: 'name', name: 'Plan Name', required: true, value: '', type: 'text' },
    { id: 2, code: 'services', name: 'Services', required: false, value: '', type: 'multiSelect' },
    { id: 3, code: 'status', name: 'Active', value: true, type: 'checkbox' }
];

export const packageForm = [
    { id: 1, code: 'name', name: 'Package Name', required: true, value: '', type: 'text' },
    { id: 2, code: 'services', name: 'Services', required: false, value: '', type: 'multiSelect' },
    { id: 2, code: 'plans', name: 'Plans', required: false, value: '', type: 'multiSelect' },
    { id: 3, code: 'status', name: 'Active', value: true, type: 'checkbox' }
];
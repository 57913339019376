import { useCallback, useContext, useState } from 'react';
import { Link, NavLink, useNavigate, useNavigation } from 'react-router-dom';
import { AuthContext } from '../contexts/Auth';
import { NotificationIco } from '../assets/svg/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearPlanAndServices, getSelectedPlans } from '../store/features/planSlice';
import { claerBilling } from '../store/features/billingSlice';
import InfoModal from './InfoModal';
import { Constant } from '../constants';

const logo = require('../assets/images/logo.png');
const Header = () => {
    const [modalInfo, setModalInfo] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const billing = useSelector(getSelectedPlans);
    const { logout } = useContext(AuthContext);

    const clearAll = () => {
        let msg = { ...modalInfo };
        msg = { show: true, type: Constant.warning, msg: 'Are you sure you want to Exit?' };
        setModalInfo(msg);
    }

    const modalAction = useCallback((type) => {
        setModalInfo({})
        if (type === Constant.close) {
            return;
        }

        if (type === Constant.proceed) {
            dispatch(clearPlanAndServices());
            dispatch(claerBilling());
            navigate('/workshop/existingUser');
            return;
        }
    }, []);

    return (
        <>
            <div className='nav-header'>
                <nav className="navbar navbar-expand-sm bg-body-white p-0">
                    <div className="container-fluid">
                        <NavLink to={'existingUser'} className='navbar-brand p-0' onClick={() => window.location.reload()}>
                            <img src={logo} alt='logo' />
                        </NavLink>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item">
                                    <NavLink to={'newUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                                        New User
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'existingUser'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Existing User</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'plans'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Services</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={'billReports'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Reports</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink to={'orderNotes'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Order Notes</NavLink>
                                </li> */}
                                {/* <li className="nav-item">
                                    <NavLink to={'quickOrder'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Quick Order</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink to={'quickOrder1'} className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>Quick Order</NavLink>
                                </li>

                                {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown link
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li> */}
                            </ul>

                            <ul className='d-flex align-items-center'>
                                {
                                    (Object.keys(billing?.plan).length || billing?.services?.length || billing?.extras?.length || billing?.proceedFrom) ?
                                        <li className="nav-item">
                                            <button className='btn btn-secondary me-2' onClick={() => clearAll()}>Clear</button>
                                        </li>
                                        :
                                        null
                                }


                                {/* <li className="nav-item mx-5">
                                    <NavLink to={'notification'} className={'pos-relative'}>
                                        <NotificationIco />
                                        <div className='notification-wrap'>
                                            <span className='text-white text-center d-block f-12 poppins-semibold'>99+</span>
                                        </div>
                                    </NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <Link className='nav-link m-0' onClick={logout}>Logout</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            {
                modalInfo?.show &&
                <InfoModal continueBtnText='Continue' {...modalInfo} icon={true} continueBtn={true} action={(type) => modalAction(type)} />
            }
        </>

    )
}

export default Header;
import { MultiSelect } from '../../../components/MultiSelect';
import './style.css';
const extraIco = require('../../../assets/images/extra.png');

const QuickOrder = () => {
    return (
        <div className="page quick-order align-items-start d-block pt-0">
            <div className='input-container d-flex justify-content-between align-items-center'>
                <div className='d-flex'>
                    <div className=''>
                        <input type='text' className='form-control' placeholder='Rego Number' />
                    </div>
                    <div className=''>
                        <input type='text' className='form-control' placeholder='Phone Number' />
                    </div>
                    <div className=''>
                        <MultiSelect placeholder='Car Type' />
                    </div>
                    <button className='btn btn-p'>Apply</button>
                </div>
                <button className='btn  btn-primary'>Proceed</button>
            </div>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <div className=''>
                        <div className='d-flex justify-content-center'>
                            <div className='title-container'>
                                <h2 className='title text-dark'>
                                    Plans
                                </h2>
                            </div>
                        </div>
                        <div className='item-wrap'>
                            <table className='table item-table my-4'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <h6 className='item-title'>JS Polish</h6>
                                        </td>
                                        <td>
                                            <h6 className='item-title'> $99</h6>
                                        </td>
                                    </tr>

                                    <tr className='selected'>
                                        <td>
                                            <h6 className='item-title'> JS Platinum</h6>
                                        </td>
                                        <td>
                                            <h6 className='item-title'> $59</h6>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <h6 className='item-title'>JS Express</h6>
                                        </td>
                                        <td>
                                            <h6 className='item-title'> $29</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className='d-flex justify-content-center'>
                        <div className='title-container'>
                            <h2 className='title text-dark'>
                                Packages
                            </h2>
                        </div>
                    </div>
                    <div className='item-wrap'>
                        <table className='table item-table my-4'>
                            <tbody>
                                <tr>
                                    <td>
                                        <h6 className='item-title'>JS Mini Detail</h6>
                                        <p className='item-desc'>
                                            Js Polish, Interior Polish
                                        </p>
                                    </td>
                                    <td>
                                        <h6 className='item-title'> $120</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <h6 className='item-title'> JS Exterior Detail</h6>
                                        <p className='item-desc'>
                                            Clay bar / Spot buff, xterior hand polish, Wheels & Tyre, Engine Clean (Upon request)
                                        </p>
                                    </td>
                                    <td>
                                        <h6 className='item-title'> $175</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <h6 className='item-title'>JS Interior Detail</h6>
                                        <p className='item-desc'>
                                            Js Platinum,Interior Plastic Clean,Leather Seats Clean,Fabric Seats Steam Clean,Carpets & Matts Steam Clean,Leather Polish
                                        </p>
                                    </td>
                                    <td>
                                        <h6 className='item-title'> $189</h6>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <h6 className='item-title'> JS Mini Details</h6>
                                        <p className='item-desc'>
                                            Include JS Polise, Interior police...
                                        </p>
                                    </td>
                                    <td>
                                        <h6 className='item-title'> $ 20</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 className='item-title'> JS Mini Details</h6>
                                        <p className='item-desc'>
                                            Include JS Polise, Interior police...
                                        </p>
                                    </td>
                                    <td>
                                        <h6 className='item-title'> $ 20</h6>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className='col-12 col-md-8'>
                    <div className='d-flex justify-content-center'>
                        <div className='title-container'>
                            <h2 className='title text-dark'>
                                Services
                            </h2>
                        </div>
                    </div>

                    <ul className='m-0 p-0 d-flex flex-wrap'>
                        <li className='item-list'>
                            <h6 className='item-title'>Leather Clean</h6>
                            <h6 className='item-title'> $ 50</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Carpet Steam Clean</h6>
                            <h6 className='item-title'> $ 50</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Sticker Removal</h6>
                            <h6 className='item-title'> $ 35</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Headlight Polish</h6>
                            <h6 className='item-title'> $ 30</h6>
                        </li>
                        <li className='item-list selected'>
                            <h6 className='item-title'> Vacuum Process (Pet hair & sand)</h6>
                            <h6 className='item-title'> $ 30</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Seats & Mats Steam</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>


                        <li className='item-list'>
                            <h6 className='item-title'> Bugs & Tar Removal</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Buff Polis</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list selected'>
                            <h6 className='item-title'> Leather Seats Clea</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Interior Plastic Clean</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Engine Clean (Upon request)</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Fabric Seats Steam Cleanm</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'> Carpets & Matts Steam Clean</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list selected'>
                            <h6 className='item-title'> Leather Polish</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>


                        <li className='item-list'>
                            <h6 className='item-title'>JS Paint Protection Treatment</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Buff & Cut (additional charge)</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Clay bar / Spot buff</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Exterior hand polish</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Wheels & Tyres</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>High - Pressure - rinse</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Interior Polish</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Exterior wash with pH neutral shampoo</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>full duco hand wax polish</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Clean and wipe door trims</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Clean and wipe dashboard and console</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Interior windows and mirrors clean</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Wipe door and boot jambs</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>

                        <li className='item-list'>
                            <h6 className='item-title'>Vacuum seats and boot</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Vacuum interior floor mats and footwells</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Mag wheel wash process</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Exterior windows and side mirrirs clean</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                        <li className='item-list'>
                            <h6 className='item-title'>Apply tyre shine</h6>
                            <h6 className='item-title'> $ 20</h6>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default QuickOrder;
import { useEffect, useState } from "react";

export const SearchInput = ({
    name = '',
    placeHolder = '',
    value = '',
    options = [],
    onchange,
}) => {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        setIsShow(value.length >= 2)
    }, [value]);

    const onSelectItem = (selectedItem) => {
        onchange(selectedItem, 'onSelect')
        setIsShow(false);
    }
    return (
        <div className="pos-relative">
            <input
                type="text"
                name={name}
                placeholder={placeHolder}
                className="form-control"
                value={value}
                autoComplete="off"
                onChange={onchange}
            />
            {
                (value.length >= 2 && isShow && options?.length) ?
                    <ul className="p-0 m-0 search-input-res-container text-start">
                        {
                            options.map((item, index) => (
                                <li key={index}><button className="btn w-100 text-start" onClick={() => onSelectItem(item)}>{item?.regno}</button></li>
                            ))
                        }
                    </ul>
                    :
                    null
            }
        </div>
    )
}
import { useCallback, useState } from "react";
import { Delete, Edit, Error, Success, Warning } from "../assets/svg/Icons";
import { Constant } from "../constants";
import Button from "./Button";
import { Label } from "./Label";

const CONTACT_INFO = {
    name: '',
    phone: '',
    email: ''
};

const AddContactInfoModal = ({ action }) => {
    const [contactDetails, updateContactDetails] = useState([CONTACT_INFO]);

    const addNew = () => {
        if (contactDetails.length === 5) return;
        updateContactDetails([...contactDetails, CONTACT_INFO]);
    }

    const deleteContact = (index) => {
        const info = contactDetails.filter((_, i) => index !== i);
        updateContactDetails(info);
    }

    const handleInput = (e, i) => {
        const { name, value } = e?.target;
        const updateDetails = contactDetails.map((item, index) => index === i ? { ...item, [name]: value } : item);
        updateContactDetails(updateDetails);
    }
    return (
        <>
            <div className="modal fade show d-block contact-modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content  rounded-0">
                        <div className="modal-header justify-content-center border-0">
                            <div className="d-flex">
                                <h1 className="modal-title fs-5 text-center" id="staticBackdropLabel">{'Add Contact Details'}</h1>
                                {
                                    contactDetails.length < 5 &&
                                    <button className="btn btn-primary rounded btn-sm ms-4" onClick={addNew}>+ Add New</button>
                                }
                            </div>
                            <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close" onClick={() => action(Constant.close)}></button>
                        </div>
                        <div className="modal-body">
                            <table className="table table-bordered table-responsive">
                                <thead>
                                    <tr>
                                        <th className="text-center" scope="col"><Label title='#' /></th>
                                        <th scope="col"><Label title='Name' /></th>
                                        <th scope="col"><Label title='Phone number' /></th>
                                        <th scope="col"><Label title='Email' /></th>
                                        <th scope="col" className="text-center"><Label title='Action' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        contactDetails?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-center f-14">{index + 1}</td>
                                                    <td><input type="text" className="form-control f-14" placeholder="Enter name" name="name" value={item?.name} onChange={(e) => handleInput(e, index)} /></td>
                                                    <td><input type="number" className="form-control f-14" placeholder="Enter phone number" name="phone" value={item?.phone} onChange={(e) => handleInput(e, index)} /></td>
                                                    <td><input type="email" className="form-control f-14" placeholder="Enter email" name="email" value={item?.email} onChange={(e) => handleInput(e, index)} /></td>
                                                    <td>
                                                        <div className="text-center">
                                                            {/* <button className="btn"><Edit /></button> */}
                                                            <button className="btn" onClick={() => deleteContact(index)}><Delete /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <Button title={'Close'} className={'btn btn-secondary'} action={() => action(Constant.close)} />
                            {
                                contactDetails.length ?
                                    <Button title={'Save'} className={'btn btn-p'} action={() => action(Constant.proceed, contactDetails)} />
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div >
            <div className="modal-backdrop fade show"></div>
        </>
    )
}

export default AddContactInfoModal;

const styles = {
    icon: { height: '64px', width: '64px', margin: '0 auto' }
}
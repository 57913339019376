import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    promocode: '',
    washType: '',
    washPrice: '',
    extraPrice: '',
    dirtyWork: '',
    discount: '',
    gst: 10,
    total: 0,
    dirtyNotes: '',
    subTotal: 0,
    gstPrice: 0,
    qty: 1,
}

const billingSlice = createSlice({
    name: 'billing',
    initialState: initialState,
    reducers: {
        updateBilling: (state, { payload }) => {
            const { name, value } = payload;
            return { ...state, [name]: value }
        },
        updateGSTPrice: (state, { payload }) => {
        },
        claerBilling: () => {
            return initialState;
        }
    }
});

export const { updateBilling, updateGSTPrice, claerBilling } = billingSlice.actions;
export const getBilling = state => state?.billing;
export default billingSlice.reducer;
import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import billReport from './../../../assets/json/bills.json';
import { useHTTP } from '../../../hooks/useHTTP';
import './style.css';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import { Constant } from '../../../constants';
import { dateFormat } from '../../../utils/dateFormat';
import { NavLink, useNavigate } from 'react-router-dom';
import { BillingIco, Delete, Edit, File, ViewIco } from '../../../assets/svg/Icons';
import InfoModal from '../../../components/InfoModal';

function BillReports() {
    const { loading: fetchingReports, callAPI: getReport, data: reportsData, error: fetchError, success: fetchedReport } = useHTTP();
    const [activePage, setActivePage] = useState(1);
    const [modalInfo, setModalInfo] = useState({});
    const navigate = useNavigate();
    // Fetch Reports
    useEffect(() => {
        getReport(`invoice?per_page=${10}`);
    }, []);


    const handlePageChange = (page) => {
        if (page?.url !== null) {
            const url = new URL(page?.url);
            const params = new URLSearchParams(url?.search);
            const pageNumber = params.get('page');
            if (activePage === pageNumber) return;
            setActivePage(pageNumber);
            getReport(`invoice?page=${pageNumber}`, Constant.GET)
        }
    }

    const viewInvoic = (data) => {
        const reportsData = { ...data, 'from': 'reportPage' };
        navigate('/workshop/invoice', { state: { invoiceData: JSON.stringify(reportsData) } });
    }

    const editOrder = (data) => {
        navigate('/workshop/billing', { state: { orderData: JSON.stringify(data) } });
    }
    const deleteOrder = () => {
        let msg = { ...modalInfo };
        msg = { show: true, type: Constant.warning, msg: 'Are you sure you want to Delete?' };
        setModalInfo(msg);
    }

    const modalAction = useCallback((type) => {
        setModalInfo({})
        if (type === Constant.close) {
            return;
        }

        if (type === Constant.proceed) {
            return;
        }
    }, []);

    if (fetchingReports) {
        return <Loader />
    }
    return (
        <div className='page'>
            <div className='card-container mx-4 w-100'>
                <div className='d-flex'>
                    <div className='d-flex align-items-center justify-content-center w-100'>
                    </div>
                </div>

                <table className='table table-striped table-hover table-responsive reports-table'>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Rego Number</th>
                            <th>Invoice Id</th>
                            <th>Invoice Date</th>
                            <th>Total</th>
                            <th className='text-center'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportsData?.data?.map((cus, index) => {
                                const { customers, created_at, employers, invoice_id, invoice_date, total } = cus;
                                const { per_page, current_page } = reportsData.pagination;
                                return (
                                    <tr key={index}>
                                        <td>{((current_page - 1) * per_page + 1) + index}</td>
                                        <td>{customers?.name}</td>
                                        <td>{customers?.phone}</td>
                                        <td>{customers?.regno}</td>
                                        <td>{invoice_id}</td>
                                        <td>{dateFormat(invoice_date)}</td>
                                        <td>$ {Number(total).toFixed(2)}</td>
                                        <td>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                {/* <button className={'btn page-link'} onClick={() => viewInvoic(cus)}> <ViewIco /></button> */}
                                                <button className={'btn page-link'} onClick={() => editOrder(cus)}> <Edit /></button>
                                                <button className={'btn page-link mx-3'} onClick={() => deleteOrder(cus)}> <Delete /></button>
                                                <button className={'btn btn-sm btn-p'} onClick={() => viewInvoic(cus)}> Invoice</button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>

                <div className='d-flex justify-content-end'>
                    <Pagination pagination={reportsData?.pagination?.links} activePage={activePage} onClickPage={(page) => handlePageChange(page)} />
                </div>
            </div>
            {
                modalInfo?.show &&
                <InfoModal continueBtnText='Continue' {...modalInfo} icon={true} continueBtn={true} action={(type) => modalAction(type)} />
            }
        </div>
    );
}

export default BillReports; 